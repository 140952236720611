.table {
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
    &:nth-of-type(even) {
      background-color: #F1F1F1;
      td {
        background-color: #F1F1F1;
      }
    }
  }

  th,
  td {
    display: flex !important;
    align-items: center;
    padding: 8px;
    overflow: hidden;
    white-space: nowrap;

    :last-child {
      border-right: 0;
    }
  }

  td {
    border-bottom: 1px solid #EAEAEA;
    border-right: 1px solid #EAEAEA;
    background-color: #fff;
  }

  th {
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    background-color: #DCDCDC;
  }

  &.sticky {
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 4;
      width: fit-content;
    }

    .header {
      top: 0;

    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #EAEAEA;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #EAEAEA;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #EAEAEA;
    }
  }
}
